<template>
  <ManagementClientsIndex
    :clients="clients"
    :page="page"
    :export-url="exportUrl"
    :filters="statuses"
    :role="this['auth::role']"
    :title="'Management Client'"
    :total-data="totalData"
    @detail="
      $router.push({
        name: 'admin.clients-detail',
        params: { clientId: $event },
      })
    "
  />
</template>

<script>
import { mapState } from "vuex";
import ManagementClientsIndex from "@/components/managements/clients";
import { calculatePaginations } from "@/utils/calculate-paginations";
import equal from "fast-deep-equal";

export default {
  name: "ManagementAdminPage",
  components: { ManagementClientsIndex },
  data() {
    return {
      data: "Management Client",
      statuses: [
        { label: "Semua", value: undefined },
        { label: "Aktif", value: "active" },
        { label: "Tidak Aktif", value: "non_active" },
        { label: "Pending", value: "pending" },
      ],
    };
  },
  computed: {
    ...mapState({
      clients: (s) => s.admClients.clients,
      totalData: (s) => s.admClients.meta.totalData ?? 0,
    }),
    page() {
      return +(this.$route.query.page ?? 1);
    },
    search() {
      return this.$route.query.q;
    },
    rows() {
      return this.$route.query.rows ?? "10";
    },
    status() {
      return this.$route.query.status;
    },
    pages() {
      return calculatePaginations({
        perPage: +this.rows,
        totalData: this.totalData,
        currentPage: this.page,
      });
    },
    filters() {
      return {
        page: this.page,
        perPage: this.rows,
        search: this.search,
        status: this.status,
      };
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL;
      let token = this.$store.state.auth.token;
      let params = new URLSearchParams();
      params.set("token", token);
      if (this.filters.status != null)
        params.set("status", this.filters.status);

      return `${baseUrl}/v1/super-admin/clients/export?${params.toString()}`;
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler(filters, old) {
        if (equal(filters, old)) return;

        await this.$store.dispatch("admClients/listClients", filters);
      },
    },
  },
};
</script>
