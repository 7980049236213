<template>
  <c-menu :close-on-select="true">
    <c-menu-button
      borderRadius="40px"
      backgroundColor="superLightGray.900"
      pl="20px"
      pr="30px"
      py="10px"
      h="40px"
      color="gray.900"
      fontSize="14px"
      lineHeight="21px"
      fontFamily="Roboto"
      fontWeight="normal"
      :display="['none', 'flex']"
    >
      <c-image
        :src="require('@/assets/ic-filter.svg')"
        alt="icon"
        display="inline-block"
        marginRaight="5px"
      />
      Filter
    </c-menu-button>
    <c-menu-list z-index="10" min-width="240px">
      <c-menu-option-group
        @change="$emit('change', $event)"
        default-value="Semua"
        type="radio"
      >
        <c-menu-item-option
          v-for="item in filters"
          :key="item.value"
          :value="item.value"
          border-bottom="1px solid #F2F2F2"
          font-size="18px"
          line-height="27px"
          py="16px"
          px="20px"
        >
          {{ item.label }}
        </c-menu-item-option>
      </c-menu-option-group>
    </c-menu-list>
  </c-menu>
</template>

<script>
export default {
  name: "ManagementClientFilterBtn",
  props: ["filters"],
};
</script>

<style scoped></style>
