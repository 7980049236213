<template>
  <table v-chakra w="100%">
    <thead
      v-chakra
      bg="#008C8137"
      color="brand.900"
      text-align="left"
      h="40px"
      text-transform="uppercase"
      font-weight="700"
      font-size="12px"
    >
      <tr>
        <th v-chakra pl="20px">No</th>
        <th>Foto Profil</th>
        <th>Nama</th>
        <th>Nomor HP</th>
        <th>Email</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody v-if="clients.length === 0">
      <tr>
        <td colspan="7">
          <c-flex py="80px" px="20px" justifyContent="center">
            <c-box>
              <c-image
                :src="require('@/assets/empty-nutritionists.svg')"
                alt="empty"
              />
              <c-text
                color="gray.900"
                fontFamily="Roboto"
                fontSize="24px"
                marginTop="20px"
              >
                Klien tidak ditemukan
              </c-text>
            </c-box>
          </c-flex>
        </td>
      </tr>
    </tbody>

    <tbody v-else v-chakra text-align="left" font-size="14px" font-weight="400">
      <tr
        v-for="(item, index) in clients"
        :key="index"
        v-chakra
        h="90px"
        :border="index > 0 ? '1px solid #f2f2f2' : null"
      >
        <td v-chakra pl="20px">{{ +rows * (page - 1) + (index + 1) }}</td>
        <td>
          <c-image
            h="58px"
            w="58px"
            object-fit="cover"
            rounded="full"
            :src="getAvatarUrl(item.photoUrl)"
          />
        </td>
        <td>{{ item.firstName }} {{ item.lastName }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.email }}</td>
        <td v-if="item.programs.status === 'active'">
          <c-box
            rounded="full"
            d="flex"
            align-items="center"
            justify-content="center"
            bg="#0075E11A"
            border="1px solid #0075E1"
            w="fit-content"
            py="5px"
            px="10px"
          >
            <c-text
              color="#0075E1"
              font-size="10px"
              font-style="normal"
              font-weight="400"
              line-height="15px"
            >
              Aktif
            </c-text>
          </c-box>
        </td>
        <td v-else-if="item.programs.status === 'pending'">
          <c-box
            rounded="full"
            d="flex"
            align-items="center"
            justify-content="center"
            bg="rgba(244, 204, 70, 0.2)"
            border="1px solid #F4CC46"
            w="fit-content"
            py="5px"
            px="10px"
          >
            <c-text
              color="#F4CC46"
              font-size="10px"
              font-style="normal"
              font-weight="400"
              line-height="15px"
            >
              Pending
            </c-text>
          </c-box>
        </td>
        <td v-else>
          <c-box
            rounded="full"
            d="flex"
            align-items="center"
            justify-content="center"
            bg="#EA43351A"
            border="1px solid #EA4335"
            w="fit-content"
            py="5px"
            px="10px"
          >
            <c-text
              color="#EA4335"
              font-size="10px"
              font-style="normal"
              font-weight="400"
              line-height="15px"
            >
              Tidak Aktif
            </c-text>
          </c-box>
        </td>
        <td v-chakra>
          <c-button
            type="button"
            variant="outline"
            variant-color="brand"
            rounded="full"
            w="74px"
            h="30px"
            @click="$emit('detail', item.id)"
          >
            <c-text font-size="12px" font-weight="700" line-height="18px">
              Lihat
            </c-text>
          </c-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ManagementClientTable",
  props: ["clients", "rows", "page"],
  methods: {
    getAvatarUrl(avatarUrl) {
      return avatarUrl ?? "/img/photo1.744938d0.png";
    },
  },
};
</script>

<style scoped></style>
